<template>
  <div class="artist">
    <!--  arist info section-->
    <section class="artist-info-section">
      <div class="arist-profile">
        <div class="artist-profile-img">
          <img :src="`https://royal-io.imgix.net/${profileInfo.avatarAsset.filename}`" alt="artist-profile-img">
        </div>
        <p>{{ $route.params.artist }}</p>
      </div>
      <div class="artist-log">
        <div v-for="(log,i) in logs">
          <p>
            {{ log.name }}
          </p>
          <p>
            {{ splitByUnit(log.value) }}
          </p>
        </div>
      </div>
      <div class="artist-introduction">
        <div>
          <p>@{{ profileInfo.name }}</p>
          <p>{{ reduceAddress(profileInfo.owner.wallets[0].address) }}</p>
          <p>{{ profileInfo.description }}</p>
        </div>
        <div v-if="profileInfo.socialLinks.length > 0" class="artist-sns">
          <a :href=profileInfo.socialLinks[0].url target="_blank">Twitter</a>
          <a :href=profileInfo.socialLinks[1].url target="_blank">Spotify</a>
          <a :href=profileInfo.socialLinks[2].url target="_blank">Youtube</a>
        </div>
      </div>
    </section>
    <v-divider></v-divider>

    <!--  artist drops section-->
    <section class="artist-drops-section">
      <p>DROPS</p>
      <div class="artist-drops">
        <banner
            v-for="(banner, i) in editionList"
            :key="i"
            skin="artist-drops-img"
            :bannerTitle="banner.node.title"
            :bannerType="banner.node.rightsAsset.type"
            :bannerImg="`https://royal-io.imgix.net/${banner.node.heroAsset.filename}`"
            @click="goPage('edition', i, banner.node.title)"/>
      </div>
    </section>

    <!--  artist collection section-->
    <section class="artist-collection-section">
      <p>COLLECTION</p>
      <div class="artist-collection">
        <div class="recent-drops-cards">
          <card-drop
              v-for="(token, i) in tokens" :key="i"
              :artworkImg="token.node.artworkAsset.filename"
              :tier="token.node.tier.type"
              :avatarImg=" !token.node.wallet.user ? null : token.node.wallet.user.profiles[0].avatarAsset"
              :userName=" !token.node.wallet.user ? token.node.wallet.address : !token.node.wallet.user.profiles[0].avatarAsset ? token.node.wallet.address : token.node.wallet.user.profiles[0].slug"
              :editionTitle="token.node.tier.edition.rightsAsset.title"
              :editionType="token.node.tier.edition.rightsAsset.type"
              :maxSupply="token.node.tier.maxSupply"
              :artist="token.node.tier.edition.contributors[0].profile.name"
              @click="goPage('token', i)"/>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import CardDrop from "@/components/Card/CardDrop";
import Banner from "@/components/Banner/Banner";

export default {
  name: 'Artist',
  components: {
    CardDrop,
    Banner,
  },
  data() {
    return {
      logs: [
        {name: 'MONTHLY LISTENS', value: 20000000},
        {name: 'FACEBOOK', value: 11402},
        {name: 'INSTAGRAM', value: 322420200000},
        {name: 'CUMULATIVE STREAMS', value: 1000252552}
      ],
      profileInfo: [],
      editionList: [],
      tokens: [],
    }
  },
  created() {
  },
  async mounted() {
    await this.artistData()
  },
  methods: {
    async artistData() {
      await this.$http.get(`/artist/${this.$route.params.artist}`
      ).then(res => {
        this.profileInfo = res.data.profile
        this.editionList = res.data.profile.editions.edges
        this.tokens = res.data.profile.owner.wallets[1].tokens.edges
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name, index, edition) {
      this.$router.push({
        name: name,
        params: {
          edition: edition,
          tokenId: this.tokens[index].node.royalId,
          ownerAvatarImg: !this.tokens[index].node.wallet.user ? null : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? null : this.tokens[index].node.wallet.user.profiles[0].avatarAsset.filename  ,
          ownerName: !this.tokens[index].node.wallet.user ? this.tokens[index].node.wallet.address : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? this.tokens[index].node.wallet.address : this.tokens[index].node.wallet.user.profiles[0].slug,
        }
      })
    },
    splitByUnit(number) {
      let inputNumber = number < 0 ? false : number;
      let unitWords = ['', 'k+', 'M+', 'B+'];
      let splitUnit = 1000;
      let splitCount = unitWords.length;
      let resultString = '';

      for (let j = 0; j < splitCount + 1; j++) {
        if (inputNumber / Math.pow(splitUnit, j) < splitUnit && inputNumber / Math.pow(splitUnit, j) >= 1) {
          let unitResult = inputNumber / Math.pow(splitUnit, j + 1)
          resultString = String(unitResult.toFixed(3) * splitUnit) + unitWords[j];
        }
      }
      return resultString
    },
    reduceAddress(value) {
      return value.slice(0, 6) + '...' + value.slice(38, 42)
    },
  }
}
</script>